import Repository from "@/plugins/axios";

export default {
  // Получить список экскурсий
  getTours(params) {
    return Repository.get(`/tours`, { params });
  },

  // Получить страницу экскурсии
  getTour(ident) {
    return Repository.get(`/tours/${ident}`);
  },
};
